import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Container from '@csv/styleguide/src/elements/container/container';
import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';
import { AllPagesQuery } from '../generated/graphql-types';
import {
  TeamCardListItem,
  TeamCardUnorderedList,
} from '@csv/styleguide/src/features/team-card-list/team-card-list';
import TeamCard from '@csv/styleguide/src/features/team-card/team-card-v1';

export const query = graphql`
  query AllPages {
    pages: allSanityPage {
      nodes {
        id
        slug {
          current
        }
        title
        summary
        mainImage {
          alt
          image {
            asset {
              # fixed(width: 320) {
              #   src
              #   srcSet
              #   height
              # }
              fluid(maxWidth: 370, maxHeight: 208) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

interface InfoPageProps {
  data: AllPagesQuery;
}

const OverCSVContainer = styled(Container)`
  margin-left: -12px;
  margin-right: -12px;

  @media screen and (min-width: 520px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const InfoPage = ({ data }: InfoPageProps) => (
  <Layout>
    <PageHeaderComposition title="Informatie" byline="" />
    <OverCSVContainer>
      <TeamCardUnorderedList>
        {data.pages.nodes.map(p => (
          <TeamCardListItem key={p.id}>
            <TeamCard
              href={`/over-csv/${p.slug?.current}`}
              heading={p.title || ''}
              subHeading={p.summary || ''}
              // mediaSrc={p.mainImage?.image?.asset?.fluid?.src}
            />
          </TeamCardListItem>
        ))}
      </TeamCardUnorderedList>
    </OverCSVContainer>
  </Layout>
);

export default InfoPage;
